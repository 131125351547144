import React from 'react';
import Spline from '@splinetool/react-spline';

import './App.css';

function App() {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date('2024-06-12') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="content">
          <div className="spline-container">
            <Spline scene="https://prod.spline.design/W00yceXq4GS8YYSu/scene.splinecode" />
          </div>
          <div className="text-container">
            <h1>Lesh Labs</h1>
            <p>Get ready, we're stirring up something!</p>
            <div className="timer">
              {timerComponents.length ? timerComponents : <span>Time's up!</span>}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
